import { Box, Button, Card, useMediaQuery, useTheme } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { defineMessage, useIntl } from 'react-intl'
import {
    useDisconnectOuraUserMutation,
    useGetOuraOAuthStartUrlLazyQuery,
    useGetUserSourcesQuery
} from '../../graphql/generated/autogenerated'
import React, { useCallback, useMemo, useState } from 'react'
import ConnectedSource from '../ConnectedSource/ConnectedSource'
import { SourceName } from '../../enums'
import { isMicrosoftTeamsTest } from '@thriveglobal/thrive-web-core'

const CARD_HEADING = defineMessage({
    defaultMessage: 'Oura',
    description:
        'The heading for the card where users can connect their Oura account'
})

const CARD_DESCRIPTION = defineMessage({
    defaultMessage: 'Click connect to link your Oura account with Thrive',
    description: 'The description for how a user can connect their Oura account'
})

const CONNECT_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Connect to Oura',
    description:
        'The label for a button that allows users to connect their Oura account'
})

const DISCONNECT_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Disconnect',
    description:
        'The label for a button that allows users to disconnect their Oura account'
})

const SYNC_DISCLAIMER = defineMessage({
    defaultMessage: 'To refresh Oura data, open the Oura app on your phone',
    description:
        'text prompting users to open the mobile Oura app to sync their data'
})

const OuraCard = () => {
    const theme = useTheme()
    const intl = useIntl()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const { data: userSources, refetch: refetchUserSources } =
        useGetUserSourcesQuery()
    const [getOAuthUrl] = useGetOuraOAuthStartUrlLazyQuery()
    const [disconnectOuraUser] = useDisconnectOuraUserMutation()

    const source = useMemo(() => {
        return userSources?.wearables.users.sources.directSources.find(
            (s) => s.sourceName === SourceName.Oura
        )
    }, [userSources])

    const isInIframe = useMemo(() => window.self !== window.top, [])

    const isMsTeams = useMemo(() => {
        return isMicrosoftTeamsTest()
    }, [])

    const handleButtonClick = useCallback(() => {
        if (!source?.connected) {
            getOAuthUrl({
                variables: {
                    redirectPath: window.location.pathname
                }
            }).then((res) => {
                const url = res.data?.wearables.oura.oauthAuthorisationUrl
                if (url) {
                    if (isInIframe) {
                        window.open(url, '_blank')
                    } else {
                        window.location.replace(url)
                    }
                } else {
                    console.warn(
                        'No Oura authorisation url available. Redirect aborted'
                    )
                }
            })
        } else {
            disconnectOuraUser()
                .then(() => {
                    refetchUserSources()
                })
                .catch(() => {
                    console.error('Failed to disconnect Oura user')
                })
        }
    }, [
        source,
        isInIframe,
        getOAuthUrl,
        disconnectOuraUser,
        refetchUserSources
    ])

    const buttonText = useMemo(() => {
        return (
            (!!source?.connected && DISCONNECT_BUTTON_TEXT) ||
            CONNECT_BUTTON_TEXT
        )
    }, [source])

    if (isMsTeams) {
        return null
    }

    return (
        <Card
            variant="outlined"
            sx={{
                padding: isSmallScreen ? 2 : 3
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: source?.connected ? 1 : 3,
                    flexDirection:
                        source?.connected && !isSmallScreen ? 'column' : 'row',
                    justifyContent: 'space-between',
                    paddingBottom: source?.connected && !isSmallScreen ? 1 : 0,
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column'
                    }
                }}
            >
                <Box sx={{ flexShrink: 20 }}>
                    <CoreTypography variant="h5" gutterBottom>
                        {intl.formatMessage(CARD_HEADING)}
                    </CoreTypography>
                    {!source?.connected && (
                        <CoreTypography variant="body1">
                            {intl.formatMessage(CARD_DESCRIPTION)}
                        </CoreTypography>
                    )}
                </Box>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    justifyContent="space-between"
                    gap={2}
                    alignItems={!isSmallScreen ? 'center' : undefined}
                >
                    {source?.connected && (
                        <ConnectedSource
                            sourceName={SourceName.Oura}
                            showSourceName={false}
                            lastSynced={source?.lastSynced}
                        />
                    )}
                    <Box flexShrink={0}>
                        <Button
                            fullWidth={isSmallScreen}
                            variant="contained"
                            color="secondary"
                            onClick={handleButtonClick}
                        >
                            {source?.connected && (
                                <LeafIcon
                                    icon={'link-slash'}
                                    fontSize={'small'}
                                    sx={{ marginRight: '8px' }}
                                />
                            )}
                            <CoreTypography customVariant="buttonNormal">
                                {intl.formatMessage(buttonText)}
                            </CoreTypography>
                        </Button>
                    </Box>
                </Box>
                {source?.connected && (
                    <CoreTypography variant="body1" display="flex" gap={0.5}>
                        <LeafIcon icon={'circle-info'} />
                        {intl.formatMessage(SYNC_DISCLAIMER)}
                    </CoreTypography>
                )}
            </Box>
        </Card>
    )
}

export default OuraCard
