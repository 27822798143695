import { WearablesNativeConnectButton as WearablesNativeConnectButtonComponent } from '../components/WearablesNativeConnectButton'
import { withWearablesAccessVerification } from '../hoc/withWearablesAccessVerification'

// Components
export { AppleHealthCard } from '../components/AppleHealthCard'
export { MoreWellBeingAppsCard } from '../components/MoreWellBeingAppsCard'
export { OuraCard } from '../components/OuraCard'
export { WellBeingAppsDialog } from '../components/WellBeingAppsDialog'
export { WearablesWithPrivacy } from '../components/WearablesWithPrivacy'
export { PrivacyModal } from '../components/PrivacyModal'
export { AddManualActivityModal } from '../components/AddManualActivityModal'
export { ViewManualActivityModal } from '../components/ViewManualActivityModal'
export { WearablesPrivacyCard } from '../components/WearablesPrivacyCard'

export const WearablesNativeConnectButton = withWearablesAccessVerification(
    WearablesNativeConnectButtonComponent
)

// Context providers
export * from '../providers/HumanApiProvider'

// Hooks
export {
    useIsIos,
    useWearablesAccess,
    useGetUser,
    useGetWearablesUser,
    useSyncAppleHealthData,
    useAppleHealthAccessStatus
} from '../hooks'
