import { useCallback, useMemo } from 'react'
import { Button } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { defineMessage, useIntl } from 'react-intl'
import { useAppleHealthAccessStatus, useIsIos } from '../../hooks'
import { openHealthApp, requestAppleHealthAccess } from '../../utils'
import { AppleHealthPermissionType } from '../../enums'
import { useAppSelector } from '@thriveglobal/thrive-web-core'

const BUTTON_TEXT_IOS = defineMessage({
    defaultMessage: 'Connect',
    description:
        'The label for a button that allows users to connect to Apple Health'
})

const BUTTON_TEXT_IOS_SETUP_FAILED = defineMessage({
    defaultMessage: 'Open the Health app',
    description:
        'Text for a button that allows the user to open their Health app'
})

const BUTTON_TEXT_WEB = defineMessage({
    defaultMessage: 'How to connect Apple Health',
    description:
        'The label for a button that shows users how to connect to Apple health'
})

type AppleHealthConnectButtonProps = {
    onRequestedAccess: () => void
    onShowHowToConnect: () => void
}

const AppleHealthConnectButton: React.FC<AppleHealthConnectButtonProps> = ({
    onRequestedAccess,
    onShowHowToConnect
}) => {
    const intl = useIntl()
    const isIos = useIsIos()
    const {
        hasGrantedSleepAccess,
        hasGrantedStepsAccess,
        hasDeniedAccess,
        loadingAccess,
        accessError
    } = useAppleHealthAccessStatus()
    const { appleHealthPromptComplete } = useAppSelector(
        (state) => state.hybrid
    )

    const canRequestAccess = useMemo(
        () =>
            isIos &&
            !loadingAccess &&
            !hasDeniedAccess &&
            !hasGrantedSleepAccess &&
            !hasGrantedStepsAccess &&
            !appleHealthPromptComplete &&
            !accessError,
        [
            isIos,
            loadingAccess,
            hasDeniedAccess,
            hasGrantedSleepAccess,
            hasGrantedStepsAccess,
            appleHealthPromptComplete,
            accessError
        ]
    )

    const buttonText = useMemo(() => {
        if (isIos) {
            return canRequestAccess
                ? BUTTON_TEXT_IOS
                : BUTTON_TEXT_IOS_SETUP_FAILED
        } else {
            return BUTTON_TEXT_WEB
        }
    }, [canRequestAccess, isIos])

    const handleButtonClick = useCallback(() => {
        if (canRequestAccess) {
            onRequestedAccess()
            requestAppleHealthAccess([
                AppleHealthPermissionType.SLEEP,
                AppleHealthPermissionType.STEPS
            ])
        } else if (isIos && hasDeniedAccess) {
            openHealthApp()
        } else {
            onShowHowToConnect()
        }
    }, [
        canRequestAccess,
        hasDeniedAccess,
        isIos,
        onRequestedAccess,
        onShowHowToConnect
    ])

    return (
        <Button
            variant="contained"
            color="secondary"
            onClick={handleButtonClick}
            sx={{
                whiteSpace: 'nowrap',
                height: 'fit-content',
                alignSelf: 'center'
            }}
        >
            <CoreTypography customVariant="buttonNormal">
                {intl.formatMessage(buttonText)}
            </CoreTypography>
        </Button>
    )
}

export default AppleHealthConnectButton
