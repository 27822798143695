import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    useTheme,
    useMediaQuery
} from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import {
    FormattedMessage as _FormattedMessage,
    defineMessage,
    useIntl
} from 'react-intl'

import {
    LeafDialog,
    CoreTypography,
    LeafIcon,
    LeafPrivacyPolicyLink,
    LeafToSLink,
    LeafExternalLink
} from '@thriveglobal/thrive-web-leafkit'
import { ModifiedFormattedMessageType } from 'src/types/formatJs'
import { getBestFitLocale } from '@thriveglobal/thrive-web-core'

const FormattedMessage =
    _FormattedMessage as unknown as ModifiedFormattedMessageType

export interface PrivacyModalProps {
    open: boolean
    onClose: () => void
    setPrivacyModalAcknowledged: (isAcknowledged: boolean) => void
}

const MODAL_DESCRIPTION = defineMessage({
    defaultMessage:
        'We want you to know exactly how your data is being used to make effective recommendations to your well-being journey. For more information, see our list of <FAQLinkChunk>frequently asked questions page</FAQLinkChunk>',
    description: 'The description for the Health and Fitness privacy modal'
})

export const getBiometricsFAQLink = () => {
    const locale = getBestFitLocale()
        .toLocaleLowerCase()
        .replace('zh-hans', 'zh-ha') // Zendesk doesn't support zh-hans
    return `https://thriveglobal.zendesk.com/hc/${locale}/sections/7675711687187-Connected-Health-Apps`
}

const IconContainer: FC<PropsWithChildren> = ({ children }) => {
    const theme = useTheme()
    return (
        <Box
            minWidth={80}
            minHeight={80}
            maxWidth={80}
            maxHeight={80}
            borderRadius="50%"
            sx={{
                background: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize={theme.typography.pxToRem(60)}
            marginRight={3}
        >
            {children}
        </Box>
    )
}

export const PrivacyModal: FC<PrivacyModalProps> = ({
    open,
    onClose,
    setPrivacyModalAcknowledged
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile}
            data-testid="wearable-privacy-modal"
            dialogTitle={
                <>
                    <CoreTypography variant="h3" color={'text.primary'} pb={1}>
                        <FormattedMessage
                            defaultMessage="How your data is managed"
                            description="Title of the privacy modal"
                        />
                    </CoreTypography>
                    <CoreTypography variant="body1">
                        {formatMessage(MODAL_DESCRIPTION, {
                            FAQLinkChunk: (chunk: React.ReactNode) => (
                                <LeafExternalLink
                                    display="inline-flex"
                                    externalLink={getBiometricsFAQLink()}
                                    iconFontSize="inherit"
                                    tabIndex={0}
                                >
                                    {chunk}
                                </LeafExternalLink>
                            )
                        })}
                    </CoreTypography>
                </>
            }
        >
            <DialogContent
                sx={{
                    paddingX: isMobile ? 4 : 8,
                    paddingBottom: 0,
                    paddingTop: 0
                }}
            >
                <Box display="flex" alignItems="center" py={3}>
                    <IconContainer>
                        <LeafIcon
                            icon={'chart-line'}
                            fontSize={'inherit'}
                            color={'inherit'}
                        />
                    </IconContainer>
                    <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <CoreTypography variant="h5">
                            <FormattedMessage
                                defaultMessage="Automatic progress update"
                                description="Title of the how we collect data"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="You don’t have to lift a finger. We automatically check-in to your Challenge Microsteps for you."
                                description="Description of the how we collect data"
                            />
                        </CoreTypography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" py={3}>
                    <IconContainer>
                        <LeafIcon
                            icon={'eye-slash'}
                            fontSize={'inherit'}
                            color={'inherit'}
                        />
                    </IconContainer>
                    <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <CoreTypography variant="h5">
                            <FormattedMessage
                                defaultMessage="Your data is safe with us"
                                description="Title of the how we use the data"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="Your data will be anonymized and aggregated into groups of no less than 20 people. Your anonymized data may be shared with your employer to provide trends and guide well-being strategies for your company."
                                description="Description of the how we use the data"
                            />
                        </CoreTypography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" py={3}>
                    <IconContainer>
                        <LeafIcon
                            icon={'lock'}
                            fontSize={'inherit'}
                            color={'inherit'}
                        />
                    </IconContainer>
                    <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <CoreTypography variant="h5">
                            <FormattedMessage
                                defaultMessage="You're in control"
                                description="Title of the how we manage the data"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="Choose which data you’d like to share. You may request a copy of your data or for it to be deleted anytime."
                                description="Description of the how we manage the data"
                            />
                        </CoreTypography>
                    </Box>
                </Box>

                <Box py={3} gap={1} display={'flex'} flexDirection={'column'}>
                    <CoreTypography
                        variant="caption"
                        color={theme.palette.grey[700]}
                    >
                        <FormattedMessage
                            defaultMessage={
                                'Thrive only collects step and sleep data from wearables, and never sensitive data. While Human API facilitates this connection, Thrive only accesses the data explicitly agreed upon. If the pairing process includes broader language, please refer to this disclaimer as the accurate reflection of what Thrive collects.'
                            }
                            description={'Thrive wearables data disclaimer'}
                        />
                    </CoreTypography>

                    <CoreTypography
                        variant="caption"
                        color={theme.palette.grey[700]}
                    >
                        <FormattedMessage
                            defaultMessage="For more information, please visit <policyLink>Thrive’s Privacy Policy</policyLink> and <termsLink>Terms of Service</termsLink>."
                            description="Terms of service information with link to the privacy policy"
                            values={{
                                policyLink: (chunk: React.ReactNode[]) => (
                                    <LeafPrivacyPolicyLink>
                                        {chunk}
                                    </LeafPrivacyPolicyLink>
                                ),
                                termsLink: (chunk: React.ReactNode[]) => (
                                    <LeafToSLink>{chunk}</LeafToSLink>
                                )
                            }}
                        />
                    </CoreTypography>
                </Box>
            </DialogContent>
            <DialogActions
                disableSpacing
                sx={{
                    paddingX: isMobile ? 4 : 8,
                    paddingBottom: 8,
                    paddingTop: 0,
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'start'
                }}
            >
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        setPrivacyModalAcknowledged(true)
                        onClose()
                    }}
                    size="large"
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Done"
                            description="Button text to confirm user read the privacy information"
                        />
                    </CoreTypography>
                </Button>
            </DialogActions>
        </LeafDialog>
    )
}

export default PrivacyModal
