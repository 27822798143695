import { Card, CardContent } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import {
    defineMessage,
    FormattedMessage as _FormattedMessage,
    useIntl
} from 'react-intl'
import { ModifiedFormattedMessageType } from 'src/types/formatJs'

const FormattedMessage =
    _FormattedMessage as unknown as ModifiedFormattedMessageType

const MAIN_PAGE_HEADER = defineMessage({
    defaultMessage: 'Your privacy is important to us',
    description: 'The header for the Well-being apps page description'
})

type WearablesPrivacyCardProps = {
    onShowPrivacyModal: () => void
}

const WearablesPrivacyCard: React.FC<WearablesPrivacyCardProps> = ({
    onShowPrivacyModal
}) => {
    const theme = useTheme()
    const intl = useIntl()

    return (
        <Card
            variant="outlined"
            sx={{
                padding: 3,
                backgroundColor: theme.palette.secondary.main
            }}
        >
            <CardContent
                sx={{
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <CoreTypography variant="h4">
                    {intl.formatMessage(MAIN_PAGE_HEADER)}
                </CoreTypography>

                <CoreTypography variant="body1">
                    <FormattedMessage
                        defaultMessage={
                            'We collect only the sleep and step data needed to help you track well-being activities and receive personalized recommendations, nothing more. <link>Learn more</link> about how your data is used and how to manage it.'
                        }
                        description={'Description for well-being app page'}
                        values={{
                            link: (chunk: React.ReactNode[]) => (
                                <CoreTypography
                                    onClick={onShowPrivacyModal}
                                    sx={{
                                        cursor: 'pointer',
                                        display: 'inline',
                                        color: theme.palette.primary.main,
                                        textDecoration: 'underline'
                                    }}
                                >
                                    {chunk}
                                </CoreTypography>
                            )
                        }}
                    />
                </CoreTypography>
            </CardContent>
        </Card>
    )
}

export default WearablesPrivacyCard
